html,
body {
  font-family: "Helvetica";
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

input {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  background: none;
  outline: none;
  border: none;
  color: white;
  font-size: 48px;
  font-weight: bolder;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  mix-blend-mode: difference;
}
